<template>
  <svg viewBox="0 0 19 19">
    <path
      d="M18.1175 9.7025C18.1175 9.11 18.065 8.5475 17.975 8H9.5V11.3825H14.3525C14.135 12.4925 13.4975 13.43 12.5525 14.0675V16.3175H15.4475C17.1425 14.75 18.1175 12.44 18.1175 9.7025Z"
      fill="#4285F4"
    />
    <path
      d="M9.4993 18.5003C11.9293 18.5003 13.9618 17.6903 15.4468 16.3178L12.5518 14.0678C11.7418 14.6078 10.7143 14.9378 9.4993 14.9378C7.1518 14.9378 5.1643 13.3553 4.4518 11.2178H1.4668V13.5353C2.9443 16.4753 5.9818 18.5003 9.4993 18.5003Z"
      fill="#34A853"
    />
    <path
      d="M4.4525 11.2173C4.265 10.6773 4.1675 10.0998 4.1675 9.49984C4.1675 8.89984 4.2725 8.32234 4.4525 7.78234V5.46484H1.4675C0.852499 6.67984 0.5 8.04484 0.5 9.49984C0.5 10.9548 0.852499 12.3198 1.4675 13.5348L4.4525 11.2173Z"
      fill="#FBBC05"
    />
    <path
      d="M9.4993 4.0625C10.8268 4.0625 12.0118 4.52 12.9493 5.4125L15.5143 2.8475C13.9618 1.3925 11.9293 0.5 9.4993 0.5C5.9818 0.5 2.9443 2.525 1.4668 5.465L4.4518 7.7825C5.1643 5.645 7.1518 4.0625 9.4993 4.0625Z"
      fill="#EA4335"
    />
  </svg>
</template>

<script>
export default {
  name: "GoogleIcon",
};
</script>
